// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { antennaId, type AntennaId } from './Antenna';
import { readerId, type ReaderId } from './Reader';
import { tagId, type TagId } from './Tag';
import { tagEvent, type default as TagEvent } from './TagEvent';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.tag_log */
export type TagLogId = Xid & { __brand: 'TagLogId' };

/** Represents the table public.tag_log */
export default interface TagLogTable {
  id: ColumnType<TagLogId, TagLogId | undefined, TagLogId>;

  antenna_id: ColumnType<AntennaId | null, AntennaId | null, AntennaId | null>;

  reader_id: ColumnType<ReaderId, ReaderId, ReaderId>;

  tag_id: ColumnType<TagId, TagId, TagId>;

  event: ColumnType<TagEvent, TagEvent, TagEvent>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type TagLog = Selectable<TagLogTable>;

export type NewTagLog = Insertable<TagLogTable>;

export type TagLogUpdate = Updateable<TagLogTable>;

export const tagLogId = xid;

export const tagLog = z.object({
  id: tagLogId,
  antenna_id: antennaId.nullable(),
  reader_id: readerId,
  tag_id: tagId,
  event: tagEvent,
  _created_at: z.string(),
});

export const tagLogInitializer = z.object({
  id: tagLogId.optional(),
  antenna_id: antennaId.optional().nullable(),
  reader_id: readerId,
  tag_id: tagId,
  event: tagEvent,
});

export const tagLogMutator = z.object({
  id: tagLogId.optional(),
  antenna_id: antennaId.optional().nullable(),
  reader_id: readerId.optional(),
  tag_id: tagId.optional(),
  event: tagEvent.optional(),
});
