// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { userId, type UserId } from './User';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.token */
export type TokenId = Xid & { __brand: 'TokenId' };

/** Represents the table public.token */
export default interface TokenTable {
  id: ColumnType<TokenId, TokenId | undefined, TokenId>;

  user_id: ColumnType<UserId, UserId, UserId>;

  expires_at: ColumnType<Date, Date | string | undefined, Date | string>;

  is_revoked: ColumnType<boolean, boolean | undefined, boolean>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type Token = Selectable<TokenTable>;

export type NewToken = Insertable<TokenTable>;

export type TokenUpdate = Updateable<TokenTable>;

export const tokenId = xid;

export const token = z.object({
  id: tokenId,
  user_id: userId,
  expires_at: z.date(),
  is_revoked: z.boolean(),
  _created_at: z.string(),
});

export const tokenInitializer = z.object({
  id: tokenId.optional(),
  user_id: userId,
  expires_at: z.date().optional(),
  is_revoked: z.boolean().optional(),
});

export const tokenMutator = z.object({
  id: tokenId.optional(),
  user_id: userId.optional(),
  expires_at: z.date().optional(),
  is_revoked: z.boolean().optional(),
});
