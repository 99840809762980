import { treaty } from '@elysiajs/eden';

import type { App } from '@app/api';

const VITE_API_URL = import.meta.env.VITE_API_URL;

if (!VITE_API_URL) {
  throw new Error('VITE_API_URL is not defined');
}

// const api: ReturnType<typeof edenTreaty<App>> = edenTreaty<App>(VITE_API_URL);
const api = treaty<App>(VITE_API_URL, {
  fetch: {
    credentials: 'include',
  },
});

export { api };
