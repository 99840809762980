// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { readerId, type ReaderId } from './Reader';
import { tagId, type TagId } from './Tag';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.reader_tag_data */
export type ReaderTagDataId = Xid & { __brand: 'ReaderTagDataId' };

/** Represents the table public.reader_tag_data */
export default interface ReaderTagDataTable {
  id: ColumnType<ReaderTagDataId, ReaderTagDataId | undefined, ReaderTagDataId>;

  reader_id: ColumnType<ReaderId, ReaderId, ReaderId>;

  tag_id: ColumnType<TagId, TagId, TagId>;

  data: ColumnType<string, string, string>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type ReaderTagData = Selectable<ReaderTagDataTable>;

export type NewReaderTagData = Insertable<ReaderTagDataTable>;

export type ReaderTagDataUpdate = Updateable<ReaderTagDataTable>;

export const readerTagDataId = xid;

export const readerTagData = z.object({
  id: readerTagDataId,
  reader_id: readerId,
  tag_id: tagId,
  data: z.string(),
  _created_at: z.string(),
});

export const readerTagDataInitializer = z.object({
  id: readerTagDataId.optional(),
  reader_id: readerId,
  tag_id: tagId,
  data: z.string(),
});

export const readerTagDataMutator = z.object({
  id: readerTagDataId.optional(),
  reader_id: readerId.optional(),
  tag_id: tagId.optional(),
  data: z.string().optional(),
});
