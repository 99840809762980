// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.kysely_migration_lock */
export type KyselyMigrationLockId = string & { __brand: 'KyselyMigrationLockId' };

/** Represents the table public.kysely_migration_lock */
export default interface KyselyMigrationLockTable {
  id: ColumnType<KyselyMigrationLockId, KyselyMigrationLockId, KyselyMigrationLockId>;

  is_locked: ColumnType<number, number | undefined, number>;
}

export type KyselyMigrationLock = Selectable<KyselyMigrationLockTable>;

export type NewKyselyMigrationLock = Insertable<KyselyMigrationLockTable>;

export type KyselyMigrationLockUpdate = Updateable<KyselyMigrationLockTable>;

export const kyselyMigrationLockId = z.string();

export const kyselyMigrationLock = z.object({
  id: kyselyMigrationLockId,
  is_locked: z.number(),
});

export const kyselyMigrationLockInitializer = z.object({
  id: kyselyMigrationLockId,
  is_locked: z.number().optional(),
});

export const kyselyMigrationLockMutator = z.object({
  id: kyselyMigrationLockId.optional(),
  is_locked: z.number().optional(),
});
