// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { z } from 'zod';

/** Represents the enum public.tag_event */
enum TagEvent {
  tag_enter = 'tag_enter',
  tag_exit = 'tag_exit',
};

export default TagEvent;

/** Zod schema for tag_event */
export const tagEvent = z.enum([
  'tag_enter',
  'tag_exit',
]);
