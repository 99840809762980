// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { userId, type UserId } from './User';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.api_key */
export type ApiKeyId = Xid & { __brand: 'ApiKeyId' };

/** Represents the table public.api_key */
export default interface ApiKeyTable {
  id: ColumnType<ApiKeyId, ApiKeyId | undefined, ApiKeyId>;

  key: ColumnType<string, string | undefined, string>;

  user_id: ColumnType<UserId | null, UserId | null, UserId | null>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type ApiKey = Selectable<ApiKeyTable>;

export type NewApiKey = Insertable<ApiKeyTable>;

export type ApiKeyUpdate = Updateable<ApiKeyTable>;

export const apiKeyId = xid;

export const apiKey = z.object({
  id: apiKeyId,
  key: z.string(),
  user_id: userId.nullable(),
  _created_at: z.string(),
});

export const apiKeyInitializer = z.object({
  id: apiKeyId.optional(),
  key: z.string().optional(),
  user_id: userId.optional().nullable(),
});

export const apiKeyMutator = z.object({
  id: apiKeyId.optional(),
  key: z.string().optional(),
  user_id: userId.optional().nullable(),
});
