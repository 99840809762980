// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { readerId, type ReaderId } from './Reader';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.mqtt_event */
export type MqttEventId = Xid & { __brand: 'MqttEventId' };

/** Represents the table public.mqtt_event */
export default interface MqttEventTable {
  id: ColumnType<MqttEventId, MqttEventId | undefined, MqttEventId>;

  reader_id: ColumnType<ReaderId, ReaderId, ReaderId>;

  payload: ColumnType<unknown, unknown, unknown>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type MqttEvent = Selectable<MqttEventTable>;

export type NewMqttEvent = Insertable<MqttEventTable>;

export type MqttEventUpdate = Updateable<MqttEventTable>;

export const mqttEventId = xid;

export const mqttEvent = z.object({
  id: mqttEventId,
  reader_id: readerId,
  payload: z.unknown(),
  _created_at: z.string(),
});

export const mqttEventInitializer = z.object({
  id: mqttEventId.optional(),
  reader_id: readerId,
  payload: z.unknown(),
});

export const mqttEventMutator = z.object({
  id: mqttEventId.optional(),
  reader_id: readerId.optional(),
  payload: z.unknown().optional(),
});
