// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { tagId, type TagId } from './Tag';
import { userId, type UserId } from './User';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Represents the table public.user_tag */
export default interface UserTagTable {
  tag_id: ColumnType<TagId, TagId, TagId>;

  user_id: ColumnType<UserId, UserId, UserId>;
}

export type UserTag = Selectable<UserTagTable>;

export type NewUserTag = Insertable<UserTagTable>;

export type UserTagUpdate = Updateable<UserTagTable>;

export const userTag = z.object({
  tag_id: tagId,
  user_id: userId,
  _created_at: z.string(),
});

export const userTagInitializer = z.object({
  tag_id: tagId,
  user_id: userId,
});

export const userTagMutator = z.object({
  tag_id: tagId.optional(),
  user_id: userId.optional(),
});
