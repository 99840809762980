// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { userId, type UserId } from './User';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.user_secret */
export type UserSecretId = Xid & { __brand: 'UserSecretId' };

/** Represents the table public.user_secret */
export default interface UserSecretTable {
  id: ColumnType<UserSecretId, UserSecretId | undefined, UserSecretId>;

  user_id: ColumnType<UserId, UserId, UserId>;

  secret: ColumnType<string, string, string>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type UserSecret = Selectable<UserSecretTable>;

export type NewUserSecret = Insertable<UserSecretTable>;

export type UserSecretUpdate = Updateable<UserSecretTable>;

export const userSecretId = xid;

export const userSecret = z.object({
  id: userSecretId,
  user_id: userId,
  secret: z.string(),
  _created_at: z.string(),
});

export const userSecretInitializer = z.object({
  id: userSecretId.optional(),
  user_id: userId,
  secret: z.string(),
});

export const userSecretMutator = z.object({
  id: userSecretId.optional(),
  user_id: userId.optional(),
  secret: z.string().optional(),
});
