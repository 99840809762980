// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { webhookId, type WebhookId } from './Webhook';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.webhook_event */
export type WebhookEventId = Xid & { __brand: 'WebhookEventId' };

/** Represents the table public.webhook_event */
export default interface WebhookEventTable {
  id: ColumnType<WebhookEventId, WebhookEventId | undefined, WebhookEventId>;

  webhook_id: ColumnType<WebhookId, WebhookId, WebhookId>;

  data: ColumnType<string, string, string>;

  status_text: ColumnType<string | null, string | null, string | null>;

  status: ColumnType<number | null, number | null, number | null>;

  response: ColumnType<string | null, string | null, string | null>;

  updated_at: ColumnType<Xid | null, Xid | null, Xid | null>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type WebhookEvent = Selectable<WebhookEventTable>;

export type NewWebhookEvent = Insertable<WebhookEventTable>;

export type WebhookEventUpdate = Updateable<WebhookEventTable>;

export const webhookEventId = xid;

export const webhookEvent = z.object({
  id: webhookEventId,
  webhook_id: webhookId,
  data: z.string(),
  status_text: z.string().nullable(),
  status: z.number().nullable(),
  response: z.string().nullable(),
  updated_at: xid.nullable(),
  _created_at: z.string(),
});

export const webhookEventInitializer = z.object({
  id: webhookEventId.optional(),
  webhook_id: webhookId,
  data: z.string(),
  status_text: z.string().optional().nullable(),
  status: z.number().optional().nullable(),
  response: z.string().optional().nullable(),
  updated_at: xid.optional().nullable(),
});

export const webhookEventMutator = z.object({
  id: webhookEventId.optional(),
  webhook_id: webhookId.optional(),
  data: z.string().optional(),
  status_text: z.string().optional().nullable(),
  status: z.number().optional().nullable(),
  response: z.string().optional().nullable(),
  updated_at: xid.optional().nullable(),
});
