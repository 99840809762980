import { redirect } from '@tanstack/react-router';

import type { AppRouterContext } from '@/app/router';
import router from '@/app/router';

export const authGuard = ({ context }: { context: AppRouterContext }) => {
  // console.log('beforeLoad: auth-guard');

  const href = router.state.location.href;

  if (!href.includes('/login') && !context.isAuthenticated) {
    throw redirect({
      to: '/login',
      search: {
        redirect: href,
      },
    });
  }
};
