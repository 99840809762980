import type { QueryClient } from '@tanstack/react-query';
import {
  Route,
  Router,
  type ToPathOption,
  createRootRouteWithContext,
  lazyRouteComponent,
} from '@tanstack/react-router';
import { z } from 'zod';

import { authGuard } from '@/guards/auth-guard';
import queryClient from '@/lib/query-client';
import fetchReaders from '@/loaders/fetch-readers';
import fetchTagLogs from '@/loaders/fetch-tag-logs';
import { authMiddleware } from '@/middlewares/auth-middleware';

type User = {
  id: string;
};

type LoggedInContext = {
  isAuthenticated: true;
  user: User;
};

type LoggedOutContext = {
  isAuthenticated: false;
  user?: undefined;
};

export type AppRouterContext = (LoggedInContext | LoggedOutContext) & {
  queryClient: QueryClient;
};

const rootRoute = createRootRouteWithContext<AppRouterContext>()({
  beforeLoad: authMiddleware,
  component: lazyRouteComponent(() => import('./layout')),
});

const publicRoutes = new Route({
  getParentRoute: () => rootRoute,
  id: 'public',
  component: lazyRouteComponent(() => import('./(public)/layout')),
});

const indexRoute = new Route({
  getParentRoute: () => publicRoutes,
  path: '/',
  component: lazyRouteComponent(() => import('./(public)/index/page')),
});

export const loginRoute = new Route({
  getParentRoute: () => publicRoutes,
  path: 'login',
  validateSearch: z.object({
    redirect: z.string().optional(),
  }),
  component: lazyRouteComponent(() => import('./(public)/login/page')),
});

const registerRoute = new Route({
  getParentRoute: () => publicRoutes,
  path: 'register',
  component: lazyRouteComponent(() => import('./(public)/register/page')),
});

const protectedRoutes = new Route({
  getParentRoute: () => rootRoute,
  id: 'protected',
  beforeLoad: authGuard,
  component: lazyRouteComponent(() => import('./(protected)/layout')),
});

const liveRoute = new Route({
  getParentRoute: () => protectedRoutes,
  path: 'live',
  loader: ({ context }) => {
    void queryClient.ensureQueryData({
      queryKey: ['reader', { userId: context.user?.id }],
      queryFn: fetchReaders,
    });
    void queryClient.ensureQueryData({
      queryKey: ['tag_log', { userId: context.user?.id }],
      queryFn: fetchTagLogs,
    });
  },
  component: lazyRouteComponent(() => import('./(protected)/live/page')),
});

const managerRoute = new Route({
  getParentRoute: () => protectedRoutes,
  path: 'manager',
  loader: ({ context: _ctx }) => {
    // void queryClient.ensureQueryData({
    //   queryKey: ['reader', { userId: context.user!.id }],
    //   queryFn: fetchReaders,
    // });
  },
  component: lazyRouteComponent(() => import('./(protected)/manager/page')),
});

// const loginRoute = new Route({
//   getParentRoute: () => publicRoutes,
//   path: '/login',
//   beforeLoad: unauthGuard,
//   component: lazyRouteComponent(() => import('./(public)/login/page')),
// });

// const registerRoute = new Route({
//   getParentRoute: () => publicRoutes,
//   path: '/register',
//   beforeLoad: unauthGuard,
//   // component: () => <Register />,
//   component: RegisterPage,
// });

// const protectedRoutes = new Route({
//   getParentRoute: () => rootRoute,
//   id: 'protected',
//   // beforeLoad: async (props) => {
//   //   await authGuard(props);
//   // },
//   component: lazyRouteComponent(() => import('./(protected)/layout')),
// });

const routeTree = rootRoute.addChildren([
  publicRoutes.addChildren([indexRoute, loginRoute, registerRoute]),
  protectedRoutes.addChildren([liveRoute, managerRoute]),
]);

const router = new Router({
  routeTree,
  context: {
    queryClient,
    isAuthenticated: false,
  },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

type RouteType = ToPathOption<typeof routeTree>;

export type { RouteType as Route };

export default router;
