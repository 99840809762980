import type { UserId } from '@app/shared';
import { type Dispatch, createContext, useMemo, useState } from 'react';
import { useContext } from 'react';

type AuthProviderProps = {
  readonly children: React.ReactNode;
};

type AuthProviderState = {
  user?: UserId;
  setUser: Dispatch<React.SetStateAction<UserId | undefined>>;
  isLoadingUser: boolean;
  setIsLoadingUser: Dispatch<React.SetStateAction<boolean>>;
};

const AuthContext = createContext<AuthProviderState | undefined>(undefined);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<UserId>();
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);

  const value = useMemo(
    () => ({ user, setUser, isLoadingUser, setIsLoadingUser }),
    [user, isLoadingUser],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw new Error('useAuth should be used within <AuthProvider>');
  }

  return ctx;
};

export { AuthProvider, useAuth };
