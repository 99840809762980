// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.tag */
export type TagId = Xid & { __brand: 'TagId' };

/** Represents the table public.tag */
export default interface TagTable {
  id: ColumnType<TagId, TagId | undefined, TagId>;

  tid: ColumnType<string, string, string>;

  data: ColumnType<string | null, string | null, string | null>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type Tag = Selectable<TagTable>;

export type NewTag = Insertable<TagTable>;

export type TagUpdate = Updateable<TagTable>;

export const tagId = xid;

export const tag = z.object({
  id: tagId,
  tid: z.string(),
  data: z.string().nullable(),
  _created_at: z.string(),
});

export const tagInitializer = z.object({
  id: tagId.optional(),
  tid: z.string(),
  data: z.string().optional().nullable(),
});

export const tagMutator = z.object({
  id: tagId.optional(),
  tid: z.string().optional(),
  data: z.string().optional().nullable(),
});
