// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { antennaId, type AntennaId } from './Antenna';
import { tagId, type TagId } from './Tag';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.antenna_tag_data */
export type AntennaTagDataId = Xid & { __brand: 'AntennaTagDataId' };

/** Represents the table public.antenna_tag_data */
export default interface AntennaTagDataTable {
  id: ColumnType<AntennaTagDataId, AntennaTagDataId | undefined, AntennaTagDataId>;

  antenna_id: ColumnType<AntennaId, AntennaId, AntennaId>;

  tag_id: ColumnType<TagId, TagId, TagId>;

  data: ColumnType<string, string, string>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type AntennaTagData = Selectable<AntennaTagDataTable>;

export type NewAntennaTagData = Insertable<AntennaTagDataTable>;

export type AntennaTagDataUpdate = Updateable<AntennaTagDataTable>;

export const antennaTagDataId = xid;

export const antennaTagData = z.object({
  id: antennaTagDataId,
  antenna_id: antennaId,
  tag_id: tagId,
  data: z.string(),
  _created_at: z.string(),
});

export const antennaTagDataInitializer = z.object({
  id: antennaTagDataId.optional(),
  antenna_id: antennaId,
  tag_id: tagId,
  data: z.string(),
});

export const antennaTagDataMutator = z.object({
  id: antennaTagDataId.optional(),
  antenna_id: antennaId.optional(),
  tag_id: tagId.optional(),
  data: z.string().optional(),
});
