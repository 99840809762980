// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { readerId, type ReaderId } from './Reader';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.antenna */
export type AntennaId = Xid & { __brand: 'AntennaId' };

/** Represents the table public.antenna */
export default interface AntennaTable {
  id: ColumnType<AntennaId, AntennaId | undefined, AntennaId>;

  internal_id: ColumnType<string, string, string>;

  reader_id: ColumnType<ReaderId, ReaderId, ReaderId>;

  name: ColumnType<string, string | undefined, string>;

  order: ColumnType<number | null, number | null, number | null>;

  data: ColumnType<string | null, string | null, string | null>;

  rssi_min: ColumnType<number | null, number | null, number | null>;

  rssi_max: ColumnType<number | null, number | null, number | null>;

  reads_min: ColumnType<number | null, number | null, number | null>;

  reads_max: ColumnType<number | null, number | null, number | null>;

  tag_ttl: ColumnType<number | null, number | null, number | null>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type Antenna = Selectable<AntennaTable>;

export type NewAntenna = Insertable<AntennaTable>;

export type AntennaUpdate = Updateable<AntennaTable>;

export const antennaId = xid;

export const antenna = z.object({
  id: antennaId,
  internal_id: z.string(),
  reader_id: readerId,
  name: z.string(),
  order: z.number().nullable(),
  data: z.string().nullable(),
  rssi_min: z.number().nullable(),
  rssi_max: z.number().nullable(),
  reads_min: z.number().nullable(),
  reads_max: z.number().nullable(),
  tag_ttl: z.number().nullable(),
  _created_at: z.string(),
});

export const antennaInitializer = z.object({
  id: antennaId.optional(),
  internal_id: z.string(),
  reader_id: readerId,
  name: z.string().optional(),
  order: z.number().optional().nullable(),
  data: z.string().optional().nullable(),
  rssi_min: z.number().optional().nullable(),
  rssi_max: z.number().optional().nullable(),
  reads_min: z.number().optional().nullable(),
  reads_max: z.number().optional().nullable(),
  tag_ttl: z.number().optional().nullable(),
});

export const antennaMutator = z.object({
  id: antennaId.optional(),
  internal_id: z.string().optional(),
  reader_id: readerId.optional(),
  name: z.string().optional(),
  order: z.number().optional().nullable(),
  data: z.string().optional().nullable(),
  rssi_min: z.number().optional().nullable(),
  rssi_max: z.number().optional().nullable(),
  reads_min: z.number().optional().nullable(),
  reads_max: z.number().optional().nullable(),
  tag_ttl: z.number().optional().nullable(),
});
