import { api } from '@app/shared';

import type { AppRouterContext } from '@/app/router';

export const authMiddleware = async ({
  context,
}: {
  context: AppRouterContext;
}) => {
  // console.log('beforeLoad: auth-middleware');

  const { data } = await api.auth.me.get();

  if (data !== null) {
    context.isAuthenticated = data.data.isAuthenticated;
    context.user = data.data.user;
  }

  return context;
};
