// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.reader */
export type ReaderId = Xid & { __brand: 'ReaderId' };

/** Represents the table public.reader */
export default interface ReaderTable {
  id: ColumnType<ReaderId, ReaderId | undefined, ReaderId>;

  mac_address: ColumnType<string, string, string>;

  name: ColumnType<string, string | undefined, string>;

  serial_number: ColumnType<string | null, string | null, string | null>;

  order: ColumnType<number | null, number | null, number | null>;

  data: ColumnType<string | null, string | null, string | null>;

  options: ColumnType<unknown, unknown | undefined, unknown>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type Reader = Selectable<ReaderTable>;

export type NewReader = Insertable<ReaderTable>;

export type ReaderUpdate = Updateable<ReaderTable>;

export const readerId = xid;

export const reader = z.object({
  id: readerId,
  mac_address: z.string(),
  name: z.string(),
  serial_number: z.string().nullable(),
  order: z.number().nullable(),
  data: z.string().nullable(),
  options: z.unknown(),
  _created_at: z.string(),
});

export const readerInitializer = z.object({
  id: readerId.optional(),
  mac_address: z.string(),
  name: z.string().optional(),
  serial_number: z.string().optional().nullable(),
  order: z.number().optional().nullable(),
  data: z.string().optional().nullable(),
  options: z.unknown().optional(),
});

export const readerMutator = z.object({
  id: readerId.optional(),
  mac_address: z.string().optional(),
  name: z.string().optional(),
  serial_number: z.string().optional().nullable(),
  order: z.number().optional().nullable(),
  data: z.string().optional().nullable(),
  options: z.unknown().optional(),
});
