import { api } from '@app/shared';

export default async function fetchTagLogs() {
  const { data } = await api['tag-log'].index.get({
    query: {
      includeExited: 'true',
      includeOnlyValids: 'true',
      exitedFor: '60',
    },
  });

  return data;
}

export type FetchTagLogsData = Awaited<ReturnType<typeof fetchTagLogs>>;
