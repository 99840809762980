// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { readerId, type ReaderId } from './Reader';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.mqtt_command */
export type MqttCommandId = Xid & { __brand: 'MqttCommandId' };

/** Represents the table public.mqtt_command */
export default interface MqttCommandTable {
  id: ColumnType<MqttCommandId, MqttCommandId | undefined, MqttCommandId>;

  reader_id: ColumnType<ReaderId, ReaderId, ReaderId>;

  command_id: ColumnType<Xid, Xid, Xid>;

  command: ColumnType<string, string, string>;

  payload: ColumnType<unknown, unknown, unknown>;

  response: ColumnType<unknown | null, unknown | null, unknown | null>;

  response_at: ColumnType<Date | null, Date | string | null, Date | string | null>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type MqttCommand = Selectable<MqttCommandTable>;

export type NewMqttCommand = Insertable<MqttCommandTable>;

export type MqttCommandUpdate = Updateable<MqttCommandTable>;

export const mqttCommandId = xid;

export const mqttCommand = z.object({
  id: mqttCommandId,
  reader_id: readerId,
  command_id: xid,
  command: z.string(),
  payload: z.unknown(),
  response: z.unknown().nullable(),
  response_at: z.date().nullable(),
  _created_at: z.string(),
});

export const mqttCommandInitializer = z.object({
  id: mqttCommandId.optional(),
  reader_id: readerId,
  command_id: xid,
  command: z.string(),
  payload: z.unknown(),
  response: z.unknown().optional().nullable(),
  response_at: z.date().optional().nullable(),
});

export const mqttCommandMutator = z.object({
  id: mqttCommandId.optional(),
  reader_id: readerId.optional(),
  command_id: xid.optional(),
  command: z.string().optional(),
  payload: z.unknown().optional(),
  response: z.unknown().optional().nullable(),
  response_at: z.date().optional().nullable(),
});
