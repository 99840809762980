// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { webhookId, type WebhookId } from './Webhook';
import { readerId, type ReaderId } from './Reader';
import { tagEvent, type default as TagEvent } from './TagEvent';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.webhook_reader */
export type WebhookReaderId = Xid & { __brand: 'WebhookReaderId' };

/** Represents the table public.webhook_reader */
export default interface WebhookReaderTable {
  id: ColumnType<WebhookReaderId, WebhookReaderId | undefined, WebhookReaderId>;

  webhook_id: ColumnType<WebhookId, WebhookId, WebhookId>;

  reader_id: ColumnType<ReaderId, ReaderId, ReaderId>;

  events: ColumnType<TagEvent[], TagEvent[], TagEvent[]>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type WebhookReader = Selectable<WebhookReaderTable>;

export type NewWebhookReader = Insertable<WebhookReaderTable>;

export type WebhookReaderUpdate = Updateable<WebhookReaderTable>;

export const webhookReaderId = xid;

export const webhookReader = z.object({
  id: webhookReaderId,
  webhook_id: webhookId,
  reader_id: readerId,
  events: tagEvent,
  _created_at: z.string(),
});

export const webhookReaderInitializer = z.object({
  id: webhookReaderId.optional(),
  webhook_id: webhookId,
  reader_id: readerId,
  events: tagEvent,
});

export const webhookReaderMutator = z.object({
  id: webhookReaderId.optional(),
  webhook_id: webhookId.optional(),
  reader_id: readerId.optional(),
  events: tagEvent.optional(),
});
