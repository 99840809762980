// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { readerId, type ReaderId } from './Reader';
import { userId, type UserId } from './User';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.reader_access */
export type ReaderAccessId = Xid & { __brand: 'ReaderAccessId' };

/** Represents the table public.reader_access */
export default interface ReaderAccessTable {
  id: ColumnType<ReaderAccessId, ReaderAccessId | undefined, ReaderAccessId>;

  reader_id: ColumnType<ReaderId, ReaderId, ReaderId>;

  user_id: ColumnType<UserId, UserId, UserId>;

  scope: ColumnType<string, string, string>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type ReaderAccess = Selectable<ReaderAccessTable>;

export type NewReaderAccess = Insertable<ReaderAccessTable>;

export type ReaderAccessUpdate = Updateable<ReaderAccessTable>;

export const readerAccessId = xid;

export const readerAccess = z.object({
  id: readerAccessId,
  reader_id: readerId,
  user_id: userId,
  scope: z.string(),
  _created_at: z.string(),
});

export const readerAccessInitializer = z.object({
  id: readerAccessId.optional(),
  reader_id: readerId,
  user_id: userId,
  scope: z.string(),
});

export const readerAccessMutator = z.object({
  id: readerAccessId.optional(),
  reader_id: readerId.optional(),
  user_id: userId.optional(),
  scope: z.string().optional(),
});
