import { LitteraProvider as Provider } from '@assembless/react-littera';
import type { ComponentProps } from 'react';

type LitteraProviderProps = ComponentProps<typeof Provider>;

function setLocale(locale: string) {
  localStorage.setItem('lang', locale);
}

export function LitteraProvider({ ...props }: LitteraProviderProps) {
  return (
    <Provider
      locales={['en_US', 'fr_FR']}
      setLocale={setLocale}
      // detectLocale
      {...props}
    />
  );
}
