import { match } from '@formatjs/intl-localematcher';

const availableLocales = ['en-US', 'fr-FR'];

export function getLocale(defaultLocale: string) {
  const localStorageLang = localStorage.getItem('lang') ?? '';

  const requestedLocales = [localStorageLang]
    .filter((locale) => locale !== '' ?? false)
    .filter((locale) => locale !== '*' ?? false)
    .map((locale) => locale.replace('_', '-'));

  return match(requestedLocales, availableLocales, defaultLocale); // -> 'en-US'
}
