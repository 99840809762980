import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from '@tanstack/react-router';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import router from '@/app/router';
import { TailwindIndicator } from '@/components/dev/tailwind-indicator';
import { AuthProvider } from '@/contexts/auth-provider';
import { LitteraProvider } from '@/contexts/lang-provider';
import { ThemeProvider } from '@/contexts/theme-provider';
import { getLocale } from '@/lib/get-locale';
import queryClient from '@/lib/query-client';

import './globals.css';

const defaultLocale = 'fr-FR';
const initialLocale = getLocale(defaultLocale);

const rootElement = document.querySelector('#root');

if (rootElement === null) {
  throw new Error('Root element is null');
}

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <StrictMode>
      <Suspense>
        <QueryClientProvider client={queryClient}>
          <LitteraProvider initialLocale={initialLocale.replace('-', '_')}>
            <AuthProvider>
              <ThemeProvider defaultTheme='light' storageKey='ui-theme'>
                <RouterProvider router={router} />

                {import.meta.env.PROD ? undefined : <TailwindIndicator />}
              </ThemeProvider>
            </AuthProvider>
          </LitteraProvider>

          {import.meta.env.PROD ? undefined : (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </Suspense>
    </StrictMode>,
  );
}
