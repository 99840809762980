// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.webhook */
export type WebhookId = Xid & { __brand: 'WebhookId' };

/** Represents the table public.webhook */
export default interface WebhookTable {
  id: ColumnType<WebhookId, WebhookId | undefined, WebhookId>;

  name: ColumnType<string, string, string>;

  url: ColumnType<string, string, string>;

  is_active: ColumnType<boolean, boolean | undefined, boolean>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type Webhook = Selectable<WebhookTable>;

export type NewWebhook = Insertable<WebhookTable>;

export type WebhookUpdate = Updateable<WebhookTable>;

export const webhookId = xid;

export const webhook = z.object({
  id: webhookId,
  name: z.string(),
  url: z.string(),
  is_active: z.boolean(),
  _created_at: z.string(),
});

export const webhookInitializer = z.object({
  id: webhookId.optional(),
  name: z.string(),
  url: z.string(),
  is_active: z.boolean().optional(),
});

export const webhookMutator = z.object({
  id: webhookId.optional(),
  name: z.string().optional(),
  url: z.string().optional(),
  is_active: z.boolean().optional(),
});
