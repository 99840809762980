// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { xid, type default as Xid } from './Xid';
import { type ColumnType, type Selectable, type Insertable, type Updateable } from 'kysely';
import { z } from 'zod';

/** Identifier type for public.user */
export type UserId = Xid & { __brand: 'UserId' };

/** Represents the table public.user */
export default interface UserTable {
  id: ColumnType<UserId, UserId | undefined, UserId>;

  email: ColumnType<string, string, string>;

  password: ColumnType<string, string, string>;

  _created_at: ColumnType<string, string | undefined, string>;
}

export type User = Selectable<UserTable>;

export type NewUser = Insertable<UserTable>;

export type UserUpdate = Updateable<UserTable>;

export const userId = xid;

export const user = z.object({
  id: userId,
  email: z.string(),
  password: z.string(),
  _created_at: z.string(),
});

export const userInitializer = z.object({
  id: userId.optional(),
  email: z.string(),
  password: z.string(),
});

export const userMutator = z.object({
  id: userId.optional(),
  email: z.string().optional(),
  password: z.string().optional(),
});
