// @generated
// This file is automatically generated by Kanel. Do not modify manually.

import { z } from 'zod';

/** Represents the domain public.xid */
type Xid = string;

export default Xid;

/** Zod schema for xid */
export const xid = z.string();
